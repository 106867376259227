<!-- 健康卡面 -->
<template>
  <div class="card" @click="routerTo()">
    <!-- <span class="cityTitle">{{ cardItem.city }}省卫生健康委员会</span> -->
    <div class="default-jk" v-if="cardItem.deafult == true">默认就诊卡</div>
    <span class="cityTitle" v-if="cardItem.deafult == false">河南省卫生健康委员会</span>
    <span class="cityTitle cityTitle-default" v-if="cardItem.deafult == true">河南省卫生健康委员会</span>
    <img class="iconImg" src="~@/assets/img_health/icon.png" />
    <span class="dianzi">电子健康卡</span>
    <span class="userName">{{ cardItem.name }}</span>
    <span class="idCard">
      <encryption-component :textNum="cardItem.idNumber" :start="4" />
    </span>
    <span class="mechanism">中华人民共和国国家卫生健康委员会监制</span>
    <div class="qcanvas">
      <img :src="qrImgSrc" />
    </div>
   <van-overlay :show="overlayState">
      <van-loading type="spinner" color="#1989fa">加载中...</van-loading>
    </van-overlay>
  </div>
</template>

<script>
import encryptionComponent from '@/components/util/encryptionComponent';
export default {
  name: 'card',
  components: { encryptionComponent },
  props: {
    cardItem: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      qrCodeText: '',
      qrImgSrc: require('@/assets/img_health/qrcode.png'),
      overlayState: false,
    };
  },
  created() {
    this.getqrCodeText();
  },
  methods: {
    /***
     * 通过healthId获取二维码信息
     */
    async getqrCodeText() {
      let postData = {
        healthCardId: this.cardItem.healthId,
        idType: '01',
        idNumber: this.cardItem.idNumber,
        codeType: 1,
      };
      this.overlayState = true;
      let res = await this.$healthHttpService.get('healthapi/api/search/img', postData);
      this.overlayState = false;
      // this.qrCodeText = res.data.qrCodeText;
      this.qrImgSrc = 'data:image/png;base64,' + res.data.qrCodeImg;
    },
    routerTo() {
      sessionStorage.setItem('card_cardItem', JSON.stringify(this.cardItem));
      this.$router.push('/cardInformation');
    },
  },
};
</script>
<style scoped>
.card {
  width: 8.5rem;
  height: 4.8rem;
  border-radius: 0.273973rem;
  margin: 0 auto;
  margin-top: 0.342466rem;
  position: relative;
  background-image: url('~@/assets/img_health/cardnewbg.png');
  background-size: 100% 100%;
}
.cityTitle,
.iconImg,
.dianzi,
.userName,
.idCard,
.mechanism,
.qcanvas {
  font-weight: bold;
  position: absolute;
}
.cityTitle {
  height: 0.342466rem;
  font-size: 0.346575rem;
  top: 0.465753rem;
  left: 0.438356rem;
}
.iconImg {
  width: 0.753425rem;
  height: 0.753425rem;
  top: 0.315068rem;
  left: 5.205479rem;
}
.dianzi {
  font-size: 0.410959rem;
  top: 0.39726rem;
  left: 6.068493rem;
}
.userName {
  font-size: 0.493151rem;
  top: 2.767123rem;
  left: 0.479452rem;
}
.idCard {
  font-size: 0.410959rem;
  left: 0.479452rem;
  top: 3.479452rem;
}
.mechanism {
  font-size: 0.246575rem;
  white-space: nowrap;
  font-weight: bolder;
  top: 4.109589rem;
  left: 50%;
  transform: translateX(-50%);
}
.qcanvas {
  width: 2.109589rem;
  height: 2.109589rem;
  left: 6.123288rem;
  top: 1.753425rem;
  border: 0.054795rem solid #fff;
}
.default-jk {
  font-size: 0.410959rem;
  padding: 0.105479rem;
  float: left;
  color: #ffffff;
  background-color: #febc06;
  margin-bottom: 0.136986rem;
  border-bottom-right-radius: 10px;
  border-top-left-radius: 10px;
}
.cityTitle-default{
  margin-top: 0.5rem;
}
</style>
