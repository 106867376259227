<template>
  <div>
    <div v-if="cardList.length > 0" class="cardList">
      <div v-for="(item, index) in cardList" :key="index">
        <card-component :cardItem="item" />
      </div>
    </div>
    <div v-if="visitsList.length > 0" class="cardList">
      <div
        v-for="(item, index) in visitsList"
        :key="index"
        @click="upgrade(item)"
      >
        <visits-card-component :visitsCardItem="item" />
      </div>
    </div>
    <div v-if="cardListLength === 0" class="noCard">
      <div class="noCardImg">
        <img src="~@/assets/img/appoint/weitianjiajiankangka.png" alt="" />
      </div>
    </div>
    <van-button
      type="primary"
      v-if="cardListLength < 5"
      @click="addCard"
      size="large"
      class="add-pat"
    >
      <van-icon name="plus" color="#fff" />
      注册/绑定健康卡
    </van-button>
    <p class="psText colorhui">
      你还可以绑定<span>{{
        cardNumber - cardListLength < 0 ? 0 : cardNumber - cardListLength
      }}</span
      >张卡
    </p>

    <van-dialog
      v-model="showDialog"
      :show-cancel-button="false"
      :show-confirm-button="false"
    >
      <template #default>
        <div class="a1">
          <h3 style="padding-top: 20px">绑卡需知</h3>
          <p class="dialog-message1">
            1、我院就诊实行实名制，请使用就诊人姓名、身份证号绑定，姓名须与身份证上的一致；
          </p>
          <p class="dialog-message1">
            2、请仔细检查姓名、身份证号是否输入正确，以免影响您的就诊；
          </p>
          <p class="dialog-message1">
            3、绑定其他就诊人须经本人同意，未征得其本人同意的不允许绑定。
          </p>
          <van-button class="cancle-btn" @click="showDialog = false"
            >取消</van-button
          >
          <van-button type="primary" class="confirm-btn" @click="toaddCardPage"
            >确认</van-button
          >
        </div>
        <!-- <div class="a31">
            <img
                src="~@/assets/img/ucenter/20.png"
                alt=""
                @click="showDialog = false"
            />
          </div> -->
      </template>
    </van-dialog>
  </div>
</template>

<script>
import CardComponent from "@/components/card";
import VisitsCardComponent from "@/components/visitsCard";
export default {
  name: "HelloWorld",
  components: { CardComponent, VisitsCardComponent },
  data() {
    return {
      showDialog: false,
      cardNumber: 5,
      cardList: [],
      visitsList: [],
      cardListLength: 0,
      code: "",
      wechatCode: "",
    };
  },
  created() {
    this.loading = true;
    // 从公众号菜单点击跳转到此
    // 获取到微信code
    // 将code发接口请求到openid
    // let openid = (this.code = sessionStorage.getItem('openid'));
    // openid ? this.getCardList(openid) : this.getWeChatCode();
    // 清除缓存
    //sessionStorage.removeItem('userData');
  },
  mounted() {
    let openid = (this.code = sessionStorage.getItem("openid"));
    this.$nextTick(() => {
      openid ? this.getCardList(openid) : this.getWeChatCode();
    });
  },
  methods: {
    /**
     * 通过openid拉取本院绑定卡列表
     */
    getCardList(openid) {
      this.cardList = [];
      this.visitsList = [];
      this.cardListLength = 0;
      this.$http
        .get(
          "/api/search/cards?userid=" + openid + "&t=" + new Date().getTime()
        )
        .then((res) => {
          this.loading = false;
          if (res.data) {
            // 展示卡列表
            this.cardList = res.data.healths;
            this.visitsList = res.data.visits;
            this.cardListLength =
              res.data.healths.length + res.data.visits.length;
          }
          this.$nextTick(() => {
            this.$refs.wechatBind.reloadWxLogin();
          });
        })
        .catch(() => {
          this.loading = false;
        });
    },

    /**
     * 点击就诊卡升级为健康卡
     */
    upgrade(item) {
      sessionStorage.setItem("visitsCard", JSON.stringify(item));
      this.$router.push({ path: "/upgrade" });
    },

    /**
     * 点击新增健康卡的时候
     * 跳转健康平台查看有无卡
     * 回调地址为addCardOne
     * 获取healthCode
     */
    toaddCardPage() {
      // eslint-disable-next-line no-undef
      //let redirectUrl = "http%3A%2F%2Fmu2xfbao.shenzhuo.vip%3A18085%2Findex.html%23%2FaddCardOne";

      // eslint-disable-next-line no-undef
      /*let redirectUrl = `${domain}index.html%23%2FaddCardOne`;
      window.location.href = `https://health.tengmed.com/open/getHealthCardList?redirect_uri=${redirectUrl}`;*/

      sessionStorage.setItem("backRoute", this.$route.fullPath);
      this.$router.replace({ path: "/addCardOne" });

      // let redirectUrl = `${domain}index.html%23%2FaddCardOne%3FsfRedirect%3Dtrue`;
      // window.location.href = `https://health.tengmed.com/open/getUserCode?apiVersion=2&redirect_uri=${redirectUrl}`;
    },

    /**
     *实现提示信息
     */
    addCard() {
      this.showDialog = true;
    },

    /**
     * 获取微信公众平台的code
     */
    getWeChatCode() {
      // 判断是否有微信code
      let sessionCode = (this.code = sessionStorage.getItem("WXCode"));
      if (!sessionCode) {
        // 没有则进行授权
        let redirectUrl = `${domain}index.html%23%2FhealthCard`;
        this.code = this.getUrlCode().code; // 截取code用于获取openid
        if (this.code == null || this.code === "" || this.code == undefined) {
          // 如果没有code，则去请求
          window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${this.APPID}&redirect_uri=${redirectUrl}&response_type=code&scope=snsapi_userinfo&state=1&connect_redirect=1#wechat_redirect`;
        } else {
          sessionStorage.setItem("WXCode", this.code);
          // 获取openid
          this.getOpenID(this.code);
        }
      } else {
        this.getOpenID(sessionCode);
      }
    },
    /**
     * 根据code获取openid
     */
    async getOpenID(code) {
      // 判断是否有openid
      if (sessionStorage.getItem("openid")) {
        this.getCardList(sessionStorage.getItem("openid"));
        return;
      }
      let postData = {
        code: code,
      };
      let res = await this.$http
        .post("/api/login/wx", postData)
        .catch((err) => {
          this.$toast.fail("发生错误，请关闭页面之后重新授权...");
          console.log(err);
          // 发生错误说明可能是code过期，或者session丢失，重置session重新鉴权
          sessionStorage.setItem("openid", "");
          sessionStorage.setItem("WXCode", "");
        });
      sessionStorage.setItem("openid", res.data.userId);
      this.getCardList(res.data.userId);
    },
    // 获取url中code
    getUrlCode() {
      let url = location.href;
      var s = url.indexOf("?");
      var t = url.substring(s + 1);
      let strs = t.split("&");
      let theRequest = new Object();
      for (let i = 0; i < strs.length; i++) {
        theRequest[strs[i].split("=")[0]] = strs[i].split("=")[1];
      }
      return theRequest;
    },
  },
};
</script>
<style scoped>
.cardList {
  margin: 0.2rem 0;
  display: flex;
  flex-flow: column;
}
.noCard {
  width: 100%;
  height: 10rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.add-pat {
  width: 9rem;
  border-radius: 5px;
  border: none;
  background-image: linear-gradient(to right, #59fdc5, #00d48b);
  margin-bottom: 0.5rem;
  font-size: 0.4rem;
}

.add-pat .van-icon {
  position: relative;
  top: -1px;
  left: -2px;
}
.psText span {
  color: #1bdc9b;
}
.colorhui {
  font-size: 0.38rem;
}

.a1 {
  /* height: 200px; */
  background-color: #fff;
  border-radius: 20px;
}
.a2 {
  width: 80px;
  height: 80px;
  margin: auto;
  background-color: #fff;
  border-radius: 50%;
  position: absolute;
  left: 0;
  bottom: 0;
  top: -68%;
  right: 0;
}
.a21 {
  width: 65px;
  height: 65px;
  line-height: 65px;
  border-radius: 50%;
  background-color: #dbf5ec;
  margin: auto;
  margin-top: 6px;
}
.a31 {
  height: 50px;
}
.a31 img {
  width: 50px;
  height: 50px;
}
.dialog-message {
  font-size: 0.45rem;
  padding-top: 65px;
}
.dialog-message1 {
  font-size: 0.45rem;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  color: rgba(112, 112, 112, 1);
}

.cancle-btn {
  width: 40%;
  border-radius: 25px;
  margin: 5px;
  margin-bottom: 25px;
  font-size: 0.45rem;
  border: none;
  background-color: #fff;
  background-image: linear-gradient(#d2d2d2, #b9b9b9);
  color: #fff;
}
.confirm-btn {
  width: 40%;
  border-radius: 25px;
  margin: 5px;
  margin-bottom: 25px;
  font-size: 0.45rem;
  border: none;
  background-color: #fff;
  background-image: linear-gradient(#57fdc4, #00d48b);
  color: #fff;
}
</style>
