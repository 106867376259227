<!-- 星号过滤自定义组件 -->
<template>
  <div class="textWrap">
    <span v-if="label">{{ label }}</span>
    {{ text }}
  </div>
</template>

<script>
export default {
  name: "encryptionComponent",
  props: {
    start: {
      type: Number,
      default: () => {},
    },
    textNum: {
      type: String,
      default: () => {},
    },
    label: {
      type: String,
      default: () => {},
    },
  },
  data() {
    return {
      text: "aaa",
    };
  },
  mounted() {
    this.filter();
  },
  methods: {
    filter() {
      this.text = this.textNum;
      let end = this.text.length - this.start;
      let xing = "";
      for (let i = 0; i < this.text.length - this.start * 2; i++) {
        xing += "*";
      }
      this.text = this.text.replace(this.text.substring(this.start, end), xing);
    },
  },
};
</script>
<style scoped>
.textWrap {
  display: inline-block;
}
</style>
