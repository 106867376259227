<!-- 健康卡面 -->
<template>
  <div class="card">
    <span class="cityTitle">河南省第二人民医院</span>
    <span class="dianzi">{{ visitsCardItem.cardTypeName }}</span>
    <span class="userName">{{ visitsCardItem.realName }}</span>
    <span class="idCard">
      <encryption-component :textNum="visitsCardItem.idCard" :start="4" />
    </span>
    <span class="mechanism">升级成为电子健康卡</span>
    <div class="qcanvas">
      <img :src="qrImgSrc" />
    </div>
  </div>
</template>

<script>
import encryptionComponent from '@/components/util/encryptionComponent';
export default {
  name: 'visitsCard',
  components: { encryptionComponent },
  props: {
    visitsCardItem: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      qrCodeText: '',
      qrImgSrc: require('@/assets/img_health/visitsqrcode.png'),
      overlayState: false,
    };
  },
  created() {
    this.getqrCodeText();
  },
  methods: {
    /***
     * 通过healthId获取二维码信息
     */
    async getqrCodeText() {
      let postData = {
        healthCardId: this.visitsCardItem.healthId,
        idType: '01',
        idNumber: this.visitsCardItem.idCard,
        codeType: 1,
      };
      this.overlayState = true;
      let res = await this.$healthHttpService.get(
        'healthapi/api/search/img',
        postData
      );
      this.overlayState = false;
      this.qrImgSrc = 'data:image/png;base64,' + res.data.qrCodeImg;
    },
  },
};
</script>
<style scoped>
.card {
  width: 8.5rem;
  height: 4.8rem;
  border-radius: 0.273973rem;
  margin: 0 auto;
  margin-top: 0.342466rem;
  position: relative;
  background-image: url('~@/assets/img/jiuzhenka.png');
  background-size: 100% 100%;
}
.cityTitle,
.dianzi,
.iconImg,
.userName,
.idCard,
.qcanvas {
  font-weight: bold;
  position: absolute;
}
.cityTitle {
  position: absolute;
  height: 0.342466rem;
  font-size: 0.32rem;
  top: 0.465753rem;
  left: 0.438356rem;
}
.iconImg {
  width: 0.753425rem;
  height: 0.753425rem;
  top: 0.315068rem;
  left: 5.205479rem;
}
.dianzi {
  font-size: 0.32rem;
  right: 0.45rem;
  top: 0.465753rem;
}
.userName {
  font-size: 0.410959rem;
  top: 2.5rem;
  left: 0.479452rem;
}
.idCard {
  font-size: 0.410959rem;
  left: 0.479452rem;
  top: 3.3rem;
}
.mechanism {
  white-space: nowrap;
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.4);
  font-size: 0.34rem;
  height: 32px;
  line-height: 32px;
  font-weight: normal;
  border-radius: 0px 0px 0.273973rem 0.273973rem;
}
.qcanvas {
  width: 2.109589rem;
  height: 2.109589rem;
  right: 0.45rem;
  top: 1.653425rem;
  border: 0.054795rem solid #fff;
}
.default-jk {
  font-size: 0.410959rem;
  padding: 0.205479rem;
  float: left;
  color: #ffffff;
  background-color: #febc06;
  margin-bottom: 0.136986rem;
  border-bottom-right-radius: 10px;
  border-top-left-radius: 10px;
}
</style>
